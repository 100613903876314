:root {
  /* Color */
  --primary: #ececec;
  --health: #296179;
  --mind: #3f0470;
  --body: #e5bb5f;
  --skin: #a6a6a6;
  --life: #002060;
  --body-light: rgba(229, 187, 95, 0.1);
  --skin-light: rgba(166, 166, 166, 0.1);
  --health-light: rgba(41, 97, 121, 0.1);
  --dark: #646464;
}

/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

/* Utility */
.absolute { position: absolute; }
.relative { position: relative; }
.fixed { position: fixed; }
.float-left { float: left; }
.float-right { float: right; }
.txt-center { text-align: center; }
.txt-right { text-align: right; }
.justify-left { justify-content: left; }
.justify-center { justify-content: center; }
.justify-end { justify-content: flex-end; }
.content-center { align-content: center; }
.align-center { align-items: center; }
.align-end { align-items: end; }
.flex-end { align-items: flex-end; }
.uppercase { text-transform: uppercase; }
.underline { text-decoration: underline; }
.bold { font-weight: bold; }
.italic { font-style: italic; }
.no-bullets { list-style-type: none; }
.open-bullets { list-style-image: radial-gradient(
  transparent 50%,
  white 55%,
  white 70%,
  transparent 70.1%
); }
.grid { display: grid; }
.inline-block { display: inline-block; }
.block { display: block; }

.light-bg { background-color: rgba(255,255,255,0.8); }
.life { background-color: var(--life); }
.skin { background-color: var(--skin); }
.mind { background-color: var(--mind); }
.body { background-color: var(--body); }
.health { background-color: var(--health); }
.skin-light { background-color: var(--skin-light); }
.body-light { background-color: var(--body-light); }
.health-light { background-color: var(--health-light); }
.ctxt-primary { color: var(--primary); }
.ctxt-health { color: var(--health); }
.ctxt-life { color: var(--life); }
.ctxt-mind { color: var(--mind); }
.ctxt-skin { color: var(--skin); }
.ctxt-body { color: var(--body); }
.ctxt-dark { color: var(--dark); }
.fsnav { font-size: 1.3rem; }
.fstxt-xs { font-size: 0.75rem; }
.fstxt-s { font-size: 1rem; }
.fstxt-m { font-size: 1.5rem; }
.fstxt-l { font-size: 1.75rem; }
.fshero-m { font-size: 1rem; }
.fshero-l { font-size: 1.2rem; }
.fstitle-m { font-size: 1.25rem; }
.fstitle-l { font-size: 2.25rem; }
.papyrus { font-family: Papyrus;}
.arial-narrow { font-family: arial-narrow; }
.ctxt-stroke-sm { -webkit-text-stroke: .1px var(--health); }
.ctxt-stroke-m { -webkit-text-stroke: .6px var(--health); }
.ctxt-stroke-l { -webkit-text-stroke: 1.1px var(--health); }
.txt-shadow-1 { text-shadow: 0 4px 4px #000000; }
.txt-shadow-2 { text-shadow: 0 4px 4px #000000bf; }
.txt-shadow-3 { text-shadow: 0 4px 4px #00000080; }
.txt-shadow-4 { text-shadow: 0 4px 4px #00000040; }
.box-shadow-3 { box-shadow: 0 4px 4px #00000080; }
.box-shadow-4 { box-shadow: 0 4px 4px #00000040; }
.border-radius-sm { border-radius: 15px; }
.border-radius-l { border-radius: 100px; }
.half-border-radius { border-radius: 50%; }

.line-height-5 { line-height: 50px; }
.line-height-4 { line-height: 40px; }
.gap-1 { gap: 1rem; }
.p-1 { padding: 10px; }
.p-2 { padding: 20px; }
.p-7 { padding: 70px; }
.pt1 { padding-top: 10px; }
.pt2 { padding-top: 20px; }
.pb1 { padding-bottom: 10px; }
.pb2 { padding-bottom: 20px; }
.pb6 { padding-bottom: 60px; }
.pl2 { padding-left: 20px; } 
.pl4 { padding-left: 40px; } 
.pl5 { padding-left: 50px; } 
.pr2 { padding-right: 20px; } 
.ph2 { padding-left: 20px; padding-right: 20px; }
.ph3 { padding-left: 30px; padding-right: 30px; }
.ph5 { padding-left: 50px; padding-right: 50px; }
.pv6 { padding-top: 60px; padding-bottom: 60px; }
.pv8 { padding-top: 80px; padding-bottom: 80px; }
.pv2r { padding-top: 0.2rem; padding-bottom: 0.2rem; }
.auto-margin { margin: auto; }
.center { margin: 0 auto; }
.m-1a { margin: 10px auto; }
.m-2 { margin: 20px; }
.mt1 { margin-top: 10px; }
.mt2 { margin-top: 20px; }
.mt3 { margin-top: 30px; }
.mb1 { margin-bottom: 10px; }
.ml1 { margin-left: 10px; }
.mr1 { margin-right: 10px; }
.mr2 { margin-right: 20px; }
.max-srceen-width { width: 100vw; }
.half-srceen-width { width:50vw; }
.max-srceen-height { height: 100vh; }
.max-height { height: 100%; }
.max-width { width: 100%; }
.halfw { width:50%; }
.mw-8 { width: 80%; }
.mh-95 { height: 95%; }
.full-right { right: 100%; }
.half-right { right: 50%; }
.h-minium-4 { min-height: 400px; }
.h-minium-6 { min-height: 600px; }
.h-maxium-4 { max-height: 400px; }
.h-maxium-5 { max-height: 500px; }
.w-minium-5 { min-width: 500px; }
.w-maxium-2 { max-width: 200px; }
.w-maxium-25 { max-width: 250px; }
.w-maxium-3 { max-width: 300px; }
.w-maxium-4 { max-width: 400px; }
.w-maxium-5 { max-width: 500px; }
.w-maxium-6 { max-width: 600px; }
.wfit-content { width: fit-content; }
.hfit-content { height: fit-content; }

/* Components */
.App {
  width: 100%;
  height: 100%;
}
video {
  z-index: -1;
  object-fit: cover;
}
section {
  padding-bottom: 20px;
}
a {
  color: white;
  text-decoration: none;
}
a:hover {
  color: var(--primary);
  text-decoration: underline;
}
.title {
  position: absolute;
  width: 100vw;
  top: 0;
}
.portrait {
  max-width: 50%;
  border: 2px solid var(--health);
}
.banner { 
  display: none; 
}
.bg-img {
  background-repeat: round;
  background-size: cover;
}
.btn-skin {
  border-style: solid;
  border-color: rgba(255,255,255,0.178) rgb(156, 156, 156) rgb(150,150,150);
}
.btn-mind {
  border-style: solid;
  border-color: rgba(63,4,112,0.178) rgb(59,9,112) rgb(42,4,112);
}
.btn-life {
  border-style: solid;
  border-color: rgba(255,255,255, 0.178) rgb(3, 44, 125) rgb(1, 23, 68);
}
a .skin:hover {
  background-color: #b0b0b0
}
a .mind:hover {
  background-color: #5b1792
}
a .life:hover {
  background-color: #083388
}
.navbar {
  z-index: 10;
}
.navbar.slideIn { animation: slideIn 1s; }
.navbar.slideOut { animation: slideOut .5s; }

.fadeIn-1 { animation: fadeIn 6s; }
.fadeIn-2 { animation: fadeIn 8s; }
.fadeIn-3 { animation: fadeIn 10s; }
.fadeIn-4 { animation: fadeIn 12s; }
.fadeIn-5 { animation: fadeIn 14s; }

@keyframes fadeIn {
  0% { opacity: 0; }
  66% { opacity: 0; }
  100% { opacity: 1; margin-left: 0; }
}

@keyframes slideIn {
  0% { right: 100%; }
  100% { right: 50%; }
}

@keyframes slideOut {
  0% { right: 50%; }
  100% { right: 100%; }
}


@media (min-width: 40rem) {
  .col-2 { grid-template-columns: repeat(2, 1fr); }
  .col-3 { grid-template-columns: repeat(3, 1fr); }
  .col-2-3r { grid-template-columns: 2fr repeat(3, 1fr); }
  .col-span-2 { grid-column: span 2; }
  .col-span-3 { grid-column: span 4; }
  .col-start-1 { grid-column-start: 1; }
  .col-start-2 { grid-column-start: 2; }
  .row-span-2 { grid-row: span 2; }
  .row-span-3 { grid-row: span 3; }
  .row-start-1 { grid-row-start: 1; }
  .row-start-2 { grid-row-start: 2; }
  .row-start-3 { grid-row-start: 3; }
  .fshero-m { font-size: 2rem; }
  .fshero-l { font-size: 3rem; }
  .fstitle-m { font-size: 1.75rem; }
  .fstitle-l { font-size: 4.25rem; }
  .fstxt-m { font-size: 1.75rem; }
  .fstxt-l { font-size: 2rem; }
  .flex { display: flex; }
  .hide { opacity:0;  display: none; }
  .show { opacity:1; animation: fadeIn 0.25s; }
  .health.hover:hover { background-color: #337795; }
  .healing {
    background: radial-gradient(
      rgba(255,255,255,.6) 30%,
      rgba(255,255,255,.3) 60%,
      rgba(255,255,255,0) 70%
      );
  }
  
  .navbar {
    height: 80px;
    width: 100%
  }
  .grid.show {
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 80px;
  }
  .cupping-info:first-child {
    grid-column-start: 2;
    grid-column-end: 5;
  }
  .cupping-info:nth-child(2) {
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .cupping-info:last-child {
    grid-row-start: 2;
    grid-column-start: 2;
    grid-column-end: 5;
  }
  .mat-info:nth-child(3) {
    grid-column-start: 2;
  }
  .mat-info:nth-child(4) {
    grid-column-start: 3;
  }
  .mat-info:nth-child(3), .mat-info:nth-child(4) {
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .mat-info:nth-child(5){
    grid-column: 2;
  }
  .herb-info:last-child {
    grid-column: 2;
    grid-row-end: 2;
  }
}


@media (min-width: 70rem) {
  video {
    height: 100vh;
  }

  .col-1-2 { grid-template-columns: 1fr 2fr; }
  .col-1-3 { grid-template-columns: 1fr 3fr; }
  .fshero-m { font-size: 2rem; }
  .fshero-l { font-size: 2.5rem; }
  .fstitle-m { font-size: 2.5rem; }
  .fstitle-l { font-size: 6.25rem; }
  .fstxt-m { font-size: 1.75rem; }
  .fstxt-l { font-size: 2.5rem; }
  
  .portrait { max-width: 70%; }
  .banner {
    background-repeat: no-repeat;
    max-width: 100%;
    display: block;
  }
  .navbar {
    width: 75%;
  }
}